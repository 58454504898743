export default function plugin(API) {
  API.accordionItems = (el) => {
    const elements = document.querySelectorAll(el);

    elements.forEach((element) => {
      const trigger = element.querySelector('.accordion-trigger');
      let answer = element.querySelector('.accordion-content');
      let answers = document.querySelectorAll('.accordion-content');

      trigger.addEventListener('click', (event) => {
        answers.forEach((ans) => {
          if (answer !== ans) {
            ans.classList.add('is-hidden');
            ans.previousSibling.classList.remove('active');
          }
        });

        trigger.classList.toggle('active');
        answer.classList.toggle('is-hidden');
      });
    });
  };
}
